var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"cadastro-usuario"},[_c('v-container',{attrs:{"fluid":""}},[_c('h1',{staticClass:"titulo"},[_vm._v("Gecom Promotores")]),_c('v-row',[_c('v-col',[_c('v-card',{staticStyle:{"padding-top":"16px"},attrs:{"elevation":"5"}},[_c('v-skeleton-loader',{staticStyle:{"width":"100%"},attrs:{"type":"table","loading":_vm.loading,"transition-group":'fade-transition'}},[_c('v-card-text',[_c('v-data-table',{staticClass:"table",attrs:{"dense":"","headers":_vm.dessertHeaders,"items":_vm.listCaixa,"item-key":"name","items-per-page":_vm.listCaixa.length,"hide-default-footer":"","hide-default-header":"","fixed-header":"","fixed-footer":"","mobile-breakpoint":300},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var headers = ref.props.headers;
return [_c('thead',[_c('tr',[_c('th',[_vm._v(" Promotor ")]),_c('th',{staticClass:"text-end"},[_vm._v(" Saldo Anterior ")]),_c('th',{staticClass:"text-center",attrs:{"colspan":"2"}},[_vm._v(" Caixa "),_c('div',{staticClass:"d-flex",staticStyle:{"width":"100%","border-top":"1px solid #d5d5d5"}},[_c('th',{staticStyle:{"width":"50%"}},[_vm._v("Entrada")]),_c('th',{staticStyle:{"width":"50%"}},[_vm._v("Saída")])])]),_c('th',{staticClass:"text-end"},[_vm._v(" Saldo Atual ")])])])]}},{key:"item.cod",fn:function(ref){
var item = ref.item;
return [_c('span',{class:{isPositive: item.saldo_atual >= -0.01}},[_vm._v(" "+_vm._s(item.cod.padStart(2, '0'))+" ")])]}},{key:"item.cashier_value",fn:function(ref){
var item = ref.item;
return [_c('span',{class:{isPositive: item.saldo_atual >= -0.01}},[_vm._v(" "+_vm._s(_vm._f("currency")(item.cashier_value))+" ")])]}},{key:"item.payments",fn:function(ref){
var item = ref.item;
return [_c('span',{class:{isPositive: item.saldo_atual >= -0.01}},[_vm._v(" "+_vm._s(_vm._f("currency")(item.payments))+" ")])]}},{key:"item.saldo_anterior",fn:function(ref){
var item = ref.item;
return [_c('span',{class:{isPositive: item.saldo_atual >= -0.01}},[_vm._v(" "+_vm._s(_vm._f("currency")(item.saldo_anterior))+" ")])]}},{key:"item.saldo_atual",fn:function(ref){
var item = ref.item;
return [_c('span',{class:{isPositive: item.saldo_atual >= -0.01}},[_vm._v(" "+_vm._s(_vm._f("currency")(item.saldo_atual))+" ")])]}}])},[_c('template',{slot:"body.append"},[_c('tr',[_c('td',[_vm._v("Total")]),_c('td',{staticClass:"text-end"},[_vm._v(_vm._s(_vm._f("currency")(_vm.getTotal('saldo_anterior'))))]),_c('td',{staticClass:"text-end"},[_vm._v(_vm._s(_vm._f("currency")(_vm.getTotal('entrada'))))]),_c('td',{staticClass:"text-end"},[_vm._v(_vm._s(_vm._f("currency")(_vm.getTotal('saida'))))]),_c('td',{staticClass:"text-end"},[_vm._v(_vm._s(_vm._f("currency")(_vm.getTotal('saldo_atual'))))])])])],2)],1)],1)],1)],1)],1),_c('div',{staticClass:"box-table box-table-gecom"})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }