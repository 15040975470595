<template>
  <div class="cadastro-usuario">
    <v-container
      fluid
    >
      <h1 class="titulo">Gecom Promotores</h1>

      <v-row>
        <v-col>
           <v-card elevation="5" style="padding-top: 16px;">
            <!-- <v-card-title :tag="'h1'" class="box-title">
              Promotor
            </v-card-title> -->
            <v-skeleton-loader type="table" style="width: 100%" :loading="loading" :transition-group="'fade-transition'">
              <v-card-text>
                  <v-data-table
                    dense
                    :headers="dessertHeaders"
                    :items="listCaixa"
                    item-key="name"
                    class="table"
                    :items-per-page="listCaixa.length"
                    hide-default-footer
                    hide-default-header
                    fixed-header
                    fixed-footer
                    :mobile-breakpoint="300"
                  >
                    <template
                      v-slot:header="{ props: { headers } }"
                    >
                      <thead>
                        <tr>
                          <th>
                            Promotor
                          </th>

                          <th class="text-end">
                            Saldo Anterior
                          </th>
                          <th colspan="2" class="text-center">
                            Caixa
                            <div style="width: 100%; border-top: 1px solid #d5d5d5;" class="d-flex">
                              <th style="width: 50%;">Entrada</th>
                              <th style="width: 50%;">Saída</th>
                            </div>
                            <!-- <td style="width: 100%;">
                              <th>teste</th>
                              <th>fewf</th>
                            </td> -->
                          </th>
                          <th class="text-end">
                            Saldo Atual
                          </th>
                          <!-- <th>
                            Venda Atual
                          </th>

                          <th>
                            Meta
                          </th> -->
                        </tr>
                      </thead>
                    </template>

                    <template v-slot:item.cod="{item}">
                      <span :class="{isPositive: item.saldo_atual >= -0.01}"> {{ item.cod.padStart(2, '0') }} </span>
                    </template>

                    <template v-slot:item.cashier_value="{item}">
                      <span :class="{isPositive: item.saldo_atual >= -0.01}"> {{ item.cashier_value | currency }} </span>
                    </template>

                    <template v-slot:item.payments="{item}">
                      <span :class="{isPositive: item.saldo_atual >= -0.01}"> {{ item.payments | currency }} </span>
                    </template>

                    <template v-slot:item.saldo_anterior="{item}">
                      <span :class="{isPositive: item.saldo_atual >= -0.01}"> {{ item.saldo_anterior | currency }} </span>
                    </template>
                    <template v-slot:item.saldo_atual="{item}">
                      <span :class="{isPositive: item.saldo_atual >= -0.01}"> {{ item.saldo_atual | currency }} </span>
                    </template>
                    <!-- <template v-slot:item.salesDayLastWeek="{item}">
                      <span :class="{isPositive: item.saldo_atual >= -0.01}"> {{ item.salesDayLastWeek | currency }} </span>
                    </template>

                    <template v-slot:item.sales="{item}">
                      {{ item.sales | currency }}
                    </template>

                    <template v-slot:item.metas="{item}">
                      {{ item.metas ? item.metas : '-'}} 
                    </template> -->

                    <template slot="body.append">
                      <tr>
                        <td>Total</td>
                        <td class="text-end">{{ getTotal('saldo_anterior') | currency }}</td>
                        <td class="text-end">{{ getTotal('entrada') | currency }}</td>
                        <td class="text-end">{{ getTotal('saida') | currency }}</td>
                        <td class="text-end">{{ getTotal('saldo_atual') | currency }}</td>
                      </tr>
                    </template>
                  </v-data-table>
              </v-card-text>

              <!-- <v-card-actions>
                <strong>Saldo Anterior: </strong> <span class="ml-2">{{ caixas.saldoAnterior | currency}}</span> <br />
                <strong>Saldo Atual: </strong> <span class="ml-2">{{ caixas.saldoAtual | currency}}</span>
              </v-card-actions> -->
            </v-skeleton-loader>
          </v-card>
        </v-col>
      </v-row>

      <div class="box-table box-table-gecom">
        
      </div>
    </v-container>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'Vendas',
  data: () => ({
    dessertHeaders: [
      { sortable: false, align: 'left', text: 'Promotor', value: 'cod' },
      { sortable: false, align: 'right', text: 'Saldo Anterior', value: 'saldo_anterior' },
      { sortable: false, align: 'right', text: 'Entrada', value: 'cashier_value' },
      { sortable: false, align: 'right', text: 'Saída', value: 'payments' },
      { sortable: false, align: 'right', text: 'Saldo Atual', value: 'saldo_atual' },
      // { sortable: false, align: 'right', text: 'Venda Anterior', value: 'salesDayLastWeek' },
      // { sortable: false, align: 'right', text: 'Venda Atual', value: 'sales' },
      // { sortable: false, align: 'right', text: 'Meta', value: 'metas' },
    ],

    loading: false
  }),

  mounted () {
    this.loading = true
    this.getCaixas()
      .finally(() => this.loading = false)
  },

  computed: {
    ...mapGetters('relatorioesGecomPromotores', ['caixas']),
    desserts() {
      return [{
        promotor: "teste1",
        saldo: 123.12,
        meta: 123
      }]  
    },

    listCaixa () {
      const listNegativos = this.caixas.entities.filter(it => parseFloat(it.saldo_atual) < -0.01)
      const listPositivos = this.caixas.entities.filter(it => parseFloat(it.saldo_atual) >= -0.01)
      return listNegativos.concat(listPositivos)
    }
  },

  methods: {
    ...mapActions('relatorioesGecomPromotores', ['getCaixas']),
    getTotal (type) {
      const { entities } = this.caixas
      switch (type) {
        case 'saldo_anterior':
          return entities.reduce((acc, { saldo_anterior }) => acc + saldo_anterior, 0)
        case 'saldo_atual':
          return entities.reduce((acc, { saldo_atual }) => acc + saldo_atual, 0)
        case 'entrada':
          return entities.reduce((acc, { cashier_value }) => acc + cashier_value, 0)
        case 'saida':
          return entities.reduce((acc, { payments }) => acc + payments, 0)
        default:
          return 0
      }
    }
  }

}
</script>

<style lang="scss" scoped>
 .table::v-deep {
    position: relative;
    .v-data-table__wrapper {
      padding-bottom: 40px;
      table {
        // min-width: 320px !important;
        // max-width: 320px !important;
        // width: 320px !important;
        // overflow: hidden;

        th {
          color: #FFF !important;
          font-weight: bold;
          font-size: 15px;
          text-align: right;
          background: #57d3ff;
          // color: #FFF;
        }

        thead {
          tr {
            box-shadow: 1px 1px 5px rgba(0, 0, 0, .41) !important;
          }
        }

        tr  {
          > th {
            &:nth-child(1) {
              width: 20%;
            }

            &:nth-child(2) {
              width: 20%;
            }

            &:nth-child(3) {
              width: 40%;
            }

            &:nth-child(4) {
              width: 20%;
            }
          }
        }

        tbody {
          tr {
            background-color: rgb(221, 221, 221, 0.25);

            &:nth-child(2n) {
              background-color: rgb(238, 238, 238, .25);
            }

            td {
              padding: 10px 5px;
              color: #5076ff;
              font-size: 14px;

              .isPositive {
                color: #0d7612;
              }
            }

            &:last-child {
              position: absolute;
              bottom: -10px;
              width: 100%;
              background-color: #FFF;
              display: flex;
              justify-content: flex-end;
              padding-right: 12px;
              

              td {
                color: #333 !important;
                flex-shrink: 0;
                height: auto;
                font-weight: bold;

                &:first-child {
                  width: 100%;
                  flex-shrink: 1;
                }

                &:nth-child(2) {
                  width: 20%;
                }
                &:nth-child(3) {
                  width: 20%;
                }
                &:nth-child(4) {
                  width: 20%;
                }

                &:nth-child(5) {
                  width: 20%;
                }
              }
            }
          }
        }
      }
    }
  }

  .v-card__text::v-deep {
    .v-data-table__wrapper {
      max-height: 613px;
    }
  }

  .v-card__actions {
    padding: 16px;
    display: block;
  }

  .box-title {
    font-size: 18px;
    padding-bottom: 0;
  }

  .v-card__text {
    padding-top: 0;
  }
</style>>
